// Generated by Framer (10960d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import FormSpark from "https://framerusercontent.com/modules/vkHAj2Yk0mTnbM6ZdN5c/Foxm7T4YpJpvPgDlEus0/FormSpark.js";
const FormSparkFonts = getFonts(FormSpark);

const cycleOrder = ["b8mgH97Jq", "lWnm_g98w"];

const serializationHash = "framer-Jc1gY"

const variantClassNames = {b8mgH97Jq: "framer-v-45toat", lWnm_g98w: "framer-v-nybwjv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Desktop: "b8mgH97Jq", Mobile: "lWnm_g98w"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "b8mgH97Jq"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "b8mgH97Jq", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Jc1gY", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-45toat", className)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"b8mgH97Jq"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({lWnm_g98w: {"data-framer-name": "Mobile"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1wic48v-container"} layoutDependency={layoutDependency} layoutId={"ISv_jbOrq-container"}><FormSpark borderRadius={0} bottomLeftRadius={0} bottomRightRadius={0} button={{color: "var(--token-0376b963-b1d3-4bef-907c-5739b65993ee, rgb(241, 233, 218)) /* {\"name\":\"Sepia/90\"} */", fill: "var(--token-3769711a-c3d5-444b-8a14-c0d7e2492513, rgb(77, 77, 77)) /* {\"name\":\"Dark/30\"} */", fontWeight: 600, label: "Send Message"}} email={{placeholder: "Email", value: ""}} font fontFamily={"azeret mono"} fontSize={16} fontWeight={400} formId={""} gap={12} height={"100%"} id={"ISv_jbOrq"} inputs={{color: "var(--token-3769711a-c3d5-444b-8a14-c0d7e2492513, rgb(77, 77, 77)) /* {\"name\":\"Dark/30\"} */", error: "rgb(238, 68, 68)", fill: "var(--token-2e0f4d93-050c-468b-b303-9fa024a42cb2, rgb(227, 211, 181)) /* {\"name\":\"Sepia/80\"} */", placeholderColor: "var(--token-eba411e0-7354-412c-9543-7421d13f2908, rgb(153, 153, 153)) /* {\"name\":\"Dark/60\"} */"}} isMixedBorderRadius={false} layout={"horizontal"} layoutId={"ISv_jbOrq"} message={{placeholder: "Message", value: ""}} nameField={{placeholder: "Name", value: ""}} padding={18} paddingBottom={18} paddingLeft={18} paddingPerSide={false} paddingRight={18} paddingTop={18} style={{height: "100%", width: "100%"}} topLeftRadius={0} topRightRadius={0} width={"100%"} withEmail withMessage withName {...addPropertyOverrides({lWnm_g98w: {layout: "vertical"}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Jc1gY [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Jc1gY .framer-12v1859 { display: block; }", ".framer-Jc1gY .framer-45toat { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 720px; }", ".framer-Jc1gY .framer-1wic48v-container { flex: 1 0 0px; height: 290px; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Jc1gY .framer-45toat { gap: 0px; } .framer-Jc1gY .framer-45toat > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Jc1gY .framer-45toat > :first-child { margin-left: 0px; } .framer-Jc1gY .framer-45toat > :last-child { margin-right: 0px; } }", ".framer-Jc1gY.framer-v-nybwjv .framer-45toat { width: 306px; }", ".framer-Jc1gY.framer-v-nybwjv .framer-1wic48v-container { height: 360px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 290
 * @framerIntrinsicWidth 720
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"lWnm_g98w":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 */
const Framerg3TJKfATw: React.ComponentType<Props> = withCSS(Component, css, "framer-Jc1gY") as typeof Component;
export default Framerg3TJKfATw;

Framerg3TJKfATw.displayName = "Formular";

Framerg3TJKfATw.defaultProps = {height: 290, width: 720};

addPropertyControls(Framerg3TJKfATw, {variant: {options: ["b8mgH97Jq", "lWnm_g98w"], optionTitles: ["Desktop", "Mobile"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerg3TJKfATw, [...FormSparkFonts])